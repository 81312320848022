
export const dashRoutes = [
  {
    path: "/espace/admin",
    // name: "espace.admin.espace/admin",
    meta: { auth: true },
    component: () => import("../layouts/EspaceLayout.vue"),
    children: [
      {
        path: "acceuil",
        name: "espace.admin.home",
        component: () => import("../views/espace/admin/AdminHome.vue"),
      },
      {
        path: "roles",
        name: "espace.admin.roles.list",
        component: () => import("../views/espace/admin/RoleList.vue"),
      },
      {
        path: "type-organismes",
        name: "espace.admin.typeOrganismes.list",
        component: () => import("../views/espace/admin/TypeOrganismeList.vue"),
      },
      {
        path: "organismes",
        name: "espace.admin.organismes.list",
        component: () => import("../views/espace/admin/OrganismeList.vue"),
      },
      {
        path: "organisme-fonctions",
        name: "espace.admin.organismeFonctions.list",
        component: () =>
          import("../views/espace/admin/OrganismeFonctionList.vue"),
      },
      // {
      //   path: "type-organismes",
      //   name: "espace.admin.typeOrganismes.list",
      //   component: () => import("../views/espace/admin/TypeOrganisme.vue"),
      // },
      {
        path: "ethnies",
        name: "espace.admin.ethnies.list",
        component: () => import("../views/espace/admin/EthnieList.vue"),
      },
      {
        path: "metiers",
        name: "espace.admin.metiers.list",
        component: () => import("../views/espace/admin/MetierRomeList.vue"),
      },
      {
        path: "familles",
        name: "espace.admin.familles.list",
        component: () => import("../views/espace/admin/FamilleRomeList.vue"),
      },
      {
        path: "categories",
        name: "espace.admin.categories.list",
        component: () => import("../views/espace/admin/CategorieRomeList.vue"),
      },
      {
        path: "regions",
        name: "espace.admin.regions.list",
        component: () => import("../views/espace/admin/RegionRomeList.vue"),
      },
      {
        path: "privileges",
        name: "espace.admin.privileges.list",
        component: () => import("../views/espace/admin/PrivilegesList.vue"),
      },
      {
        path: "natures",
        name: "espace.admin.natures.list",
        component: () => import("../views/espace/admin/NatureList.vue"),
      },
      {
        path: "zonevulnerable",
        name: "espace.admin.zone.vulnerable.tab",
        component: () => import("../views/espace/admin/ZoneVulnerableTab.vue"),
      },
      {
        path: "zone-vulnerable",
        name: "espace.admin.zone.vulnerable.list",
        component: () => import("../views/espace/admin/ZoneVulnerableList.vue"),
      },
      {
        path: "actualites",
        name: "espace.admin.actualites.list",
        component: () => import("../views/espace/admin/ActualiteList.vue"),
      },
      // {
      //   path: "actualites/:activiteId",
      //   name: "espace.admin.actualites.edit",
      //   component: () => import("../views/portail/ActualiteEdit.vue"),
      // },
      {
        path: "faqs",
        name: "espace.admin.faqs.list",
        component: () => import("../views/portail/FaqList.vue"),
      },
      {
        path: "fonctions",
        name: "espace.admin.fonctions.list",
        component: () => import("../views/espace/admin/FonctionList.vue"),
      },
      {
        path: "transfert-portefeuilles",
        name: "espace.admin.transfert-portefeuilles.list",
        component: () => import("../views/espace/admin/TransfertPortefeuilleList.vue"),
      },
      {
        path: "antennes",
        name: "espace.admin.antennes.list",
        component: () => import("../views/espace/admin/AntenneList.vue"),
      },
      {
        path: "azolis-home",
        name: "espace.admin.azolis.home",
        redirect: {
          name: "espace.common.azolis.home",
          params: { userRole: "admin" },
        },
      },
      {
        path: "azolis-list",
        name: "espace.admin.azolis.list",
        redirect: {
          name: "espace.common.azolis.list",
          params: { userRole: "admin" },
        },
      },
      // {
      //   path: "azolis-graphs",
      //   name: "espace.admin.azolis.graphs",
      //   component: () => import("../views/espace/admin/AzoliGraphicsBoard.vue"),
      // },
      {
        path: "agents",
        name: "espace.admin.agents.list",
        component: () => import("../views/espace/admin/AgentList.vue"),
      },
      {
        path: "api-clients",
        name: "espace.admin.api-clients.list",
        component: () => import("../views/espace/admin/ApiClientList.vue"),
      },
      {
        path: "demandeurs",
        name: "espace.admin.demandeurs.list",
        component: () => import("../views/espace/admin/DemandeurList.vue"),
      },
      {
        path: "agents/:agentId/edit",
        name: "espace.admin.agents.edit",
        component: () => import("../views/espace/admin/AgentEdit.vue"),
      },
      {
        path: "utilisateurs",
        name: "espace.admin.users.list",
        component: () => import("../views/espace/admin/UserList.vue"),
      },
      {
        path: "qualifications",
        name: "espace.admin.qualifications.list",
        component: () => import("../views/espace/admin/QualificationList.vue"),
      },
      {
        path: "salaires",
        name: "espace.admin.salaires.list",
        component: () => import("../views/espace/admin/SalaireList.vue"),
      },
      {
        path: "subventions",
        name: "espace.admin.subventions.list",
        component: () => import("../views/espace/admin/SubvenirBesoinList.vue"),
      },
      {
        path: "rechercheremplois",
        name: "espace.admin.rechercheremplois.list",
        component: () =>
          import("../views/espace/admin/RechercherEmploiList.vue"),
      },
      {
        path: "pastravail",
        name: "espace.admin.pastravail.list",
        component: () => import("../views/espace/admin/PasDeTravailList.vue"),
      },
      {
        path: "netravailpas",
        name: "espace.admin.netravailpas.list",
        component: () => import("../views/espace/admin/NeTravailPasList.vue"),
      },
      {
        path: "moyenderecherche",
        name: "espace.admin.moyenderecherche.list",
        component: () =>
          import("../views/espace/admin/MoyenDeRechercheList.vue"),
      },
      {
        path: "typeprescription",
        name: "espace.admin.typeprescription.list",
        component: () =>
          import("../views/espace/admin/TypePrescriptionList.vue"),
      },
      {
        path: "typecontenu",
        name: "espace.admin.typecontenu.list",
        component: () => import("../views/espace/admin/TypeContenuList.vue"),
      },
      {
        path: "typeactivite",
        name: "espace.admin.typeactivite.list",
        component: () => import("../views/espace/admin/TypeActiviteList.vue"),
      },
      {
        path: "tageactivite",
        name: "espace.admin.tageactivite.list",
        component: () => import("../views/espace/admin/TageActiviteList.vue"),
      },
      {
        path: "programme",
        name: "espace.admin.programme.list",
        component: () => import("../views/espace/admin/ProgrammeList.vue"),
      },
      {
        path: "parametre",
        name: "espace.admin.parametre.list",
        component: () => import("../views/espace/admin/ParametreList.vue"),
      },
      {
        path: "domaine",
        name: "espace.admin.domaine.list",
        component: () => import("../views/espace/admin/DomaineList.vue"),
      },
      {
        path: "typeHandicaps",
        name: "espace.admin.typeHandicap.list",
        component: () => import("../views/espace/admin/TypeHandicapList.vue"),
      },
      {
        path: "type-indicateur",
        name: "espace.admin.typeindicateur.list",
        component: () => import("../views/espace/admin/TypeIndicateurList.vue"),
      },
      {
        path: "/candidature-liste",
        name: "espace.admin.offre.souscrire",
        component: () =>
          import("../views/espace/entreprise/OffreSouscrireList.vue"),
        props: true,
      },
    ],
  },
];

export const MENU_ADMIN = [
  {
    to: { name: "espace.admin.home" },
    label: "Tableau de bord",
    icon: "fa fa-home",
  },
  {
    label: "Référentiels",
    subLabel: "Submenus Example",
    icon: "view-list",
    // expanded: false,
    items: [
      {
        // to: { name:'espace.admin.fonctions.list'},
        label: "Habilitations",
        items: [
          {
            to: { name: "espace.admin.fonctions.list" },
            label: "Fonction",
          },
          {
            to: { name: "espace.admin.organismes.list" },
            label: "Structrures",
          },
          {
            to: { name: "espace.admin.antennes.list" },
            label: "Antennes",
          },
          {
            to: { name: "espace.admin.typeOrganismes.list" },
            label: "Type de structures",
          },
          {
            to: { name: "espace.admin.organismeFonctions.list" },
            label: "Acteur de la fonction",
          },
        ],
      },
      {
        //to: { name: 'espace.admin.privileges.list'},
        label: "Utilisateur",
        items: [
          {
            to: { name: "espace.admin.privileges.list" },
            label: "Privileges",
          },
          {
            to: { name: "espace.admin.roles.list" },
            label: "Roles",
          },
          {
            to: { name: "espace.admin.agents.list" },
            label: "Personnel ANPE",
          },
          {
            to: { name: "espace.admin.transfert-portefeuilles.list" },
            label: "Transfert de Portefeuille",
          },
          {
            to: { name: "espace.admin.api-clients.list" },
            label: "Client API",
          },
        ],
      },

      {
        // to: { name: 'espace.admin.metiers.list'},
        label: "Référentiel des Métiers",
        items: [
          {
            to: { name: "espace.admin.regions.list" },
            label: "Région Rome",
          },
          {
            to: { name: "espace.admin.categories.list" },
            label: "Catégorie Rome",
          },
          {
            to: { name: "espace.admin.familles.list" },
            label: "Famille Rome",
          },
          {
            to: { name: "espace.admin.metiers.listt" },
            label: "Métier",
          },
        ],
      },
      {
        to: { name: "espace.admin.ethnies.list" },
        label: "Ethnies",
      },
      {
        to: { name: "espace.admin.zone.vulnerable.tab" },
        label: "Zone Vulnérable",
      },

      {
        to: { name: "espace.admin.typeprescription.list" },
        label: "Type de prescriptions",
      },
      {
        to: { name: "espace.admin.typecontenu.list" },
        label: "Type de contenus",
      },
      {
        to: { name: "espace.admin.typeindicateur.list", },
        label: "Type d'indicateur",
      },
      {
        to:  { name:'espace.admin.typeHandicap.list'},
        label: 'Type de handicap'
      },
      {
        to: { name: "espace.admin.typeactivite.list" },
        label: "Type d'activites",
      },
      {
        to: { name: "espace.admin.programme.list" },
        label: "Programmes",
      },
      {
        to: { name: "espace.admin.domaine.list" },
        label: "Domaines",
      },
      {
        to: { name: "espace.admin.parametre.list" },
        label: "Paramètres",
      },

     
    ],
  },
  {
    label: "Porte-feuille",
    expanded: false,
    items: [
   
      {
        //to: 'espace.admin.azolis.home',
        label: "Azoli",
        items: [
          {
            to: {
              name: "espace.common.azolis.home",
              params: { userRole: "admin" },
            },
            label: "Globale",
          },
          {
            to: {
              name: "espace.common.azolis.graphs",
              params: { userRole: "admin" },
            },
            label: "Graphes",
          },
          {
            to: {
              name: "espace.common.azolis.list",
              params: { userRole: "admin" },
            },
            label: "Gestion des bénéficiaires",
          },
          {
            to: {
              name: "espace.common.azolis-doublons.list",
              params: { userRole: "admin" },
            },
            label: "Gestion des doublons",
          },
          {
            to: {
              name: "espace.common.recherche.demandeur.profil",
              params: { userRole: "admin" },
            },
            label: "Matching",
            privileges: [ 'MENU.MATCHING' ]
          },
        ],
      },
      {
        //to: 'espace.admin.azolis.home',
        label: "Statisitiques",
        items: [
          {
            to: {
              name: "espace.common.suivi-evaluation.list",
              params: { userRole:  "admin" },
            },
            label: "Contrat d'objectif",
            privileges: [ 'MENU.CONTRAT_OBJECTIF_MANAGEMENT_LIST' ]
          },
        ]
        }, 
    ],
  },
  {
    label: "Les publications",
    subLabel: "Submenus Example",
    icon: "view-list",
    expanded: false,
    items: [
      {
        to: { name: "espace.admin.actualites.list" },
        label: "Actualités",
      },
    ],
  },
  {
    label: "Gestion de prises en charge",
    subLabel: "Submenus Example",
    icon: "view-list",
    expanded: false,
    items: [
      {
        to: { name: "espace.ca.pr.list" },
        label: "Liste des prescriptions",
      },
      {
        to: { name: "espace.ca.con.list" },
        label: "Liste des consignes",
      },
    ],
  },
  {
    label: "Mise en relation",
    subLabel: "Submenus Example",
    icon: "view-list",
    expanded: false,
    items: [
      {
        to: { name: "espace.admin.actualites.list" },
        label: "Gestion des entreprises",
      },
      {
        to: { name: "espace.admin.actualites.list" },
        label: "Gestion des placements",
      },
      {
        to: { name: "espace.entreprise.offre.list" },
        label: "Les offres postées",
      },
      {
        to: { name: "espace.admin.offre.souscrire" },
        label: "Les offres souscrire",
      },
      {
        to: { name: "espace.admin.actualites.list" },
        label: "Les prospections",
      },
      {
        to: { name: "espace.admin.actualites.list" },
        label: "Les placements",
      },
      {
        to: { name: "espace.admin.actualites.list" },
        label: "Outlis de recherche",
      },
    ],
  },
];
// import axios from 'axios'
import { post, put, get, remove} from '@/api/api'


export const actions = {
  fetchConstats({ commit }){
    return get('/constats?size=20&sort=id,desc')
    .then(({ data }) =>{
      commit('addConstats', data)
    })
  },
  createConstat({ commit }, constat){
    return post('/constats', constat)
    .then(({ data }) =>{
      commit('addConstat', data)
    })
  },
  updateConstat({ commit }, constat){
    return put(`/constats/${constat.id}`, constat)
    .then(({ data }) =>{
      commit('replaceConstat', data)
    })
  },
  deleteConstat({ commit }, constat){
    return remove(`/constats/${constat.id}`, constat)
    .then(({ data }) =>{
      commit('removeConstat', constat)
      return data
    })
  },
  createOrUpdateConstat({ dispatch }, constat){
    if(constat.id !== undefined){
      return dispatch('updateConstat', constat)
    }
    else {
      return dispatch('createConstat', constat)
    }
  },
  selectConstat({ commit }, constat){
    commit('setConstat', constat)
  },
  getEntrepriseConstat({ commit }, id){
    return get(`/constats/entreprise/${id}?size=20&sort=id,desc`)
    .then(({ data }) =>{
      commit('addConstats', data)
    })
  },
  getAuditConstat({ commit }, id){
    return get(`/constats/audit/${id}`)
    .then(({ data }) =>{
      commit('addConstats', data)
    })
  },

}
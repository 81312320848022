import { extractPaginationInfo } from '@/utils/pagination-util'

export const mutations = {
  addConstats(state, data){
    state.constats = [ ...data.content ]
  },
  addConstat(state, constat){
    state.constats = [ ...state.constats, constat ]
  },
  replaceConstat(state, constat){
    state.constats = state.constats.map(struc => struc.id === constat.id ? constat : struc )
  },
  removeConstat(state, constat){
    state.constats = state.constats.filter(struc => struc.id !== constat.id )
  },
  setConstat(state, constat){
    state.constat = constat
  },
}